import { isUserLoggedIn } from '../../utils/authUtils';
import { CrudServiceBase } from '../base/crudServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { UserAgencyLoginDto } from './dto/userAgencyLoginDto';

export class UserAgencyLoginService extends CrudServiceBase<UserAgencyLoginDto> {
    constructor() {
        super(Endpoint.UserAgencyLogin);
        this.internalHttp = httpApi;
    }

    public async getByUserId(userId: number) {
        isUserLoggedIn();
        const result = await httpApi.get(this.endpoint.Custom(`GetByUserId/?userId=${userId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getAgentLoginsByUserId(userId: number) {
        isUserLoggedIn();
        const result = await httpApi.get(this.endpoint.Custom(`GetAgentLoginsByUserIdWithDatas/?userId=${userId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getInsurerLoginsByInsurerId(insurerId: number) {
        isUserLoggedIn();
        const result = await httpApi.get(this.endpoint.Custom(`GetInsurerLoginsByInsurerIdWithDatas/?insurerId=${insurerId}`));
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    async saveAgentLogins(requestBody: any, userId: number) {
        const result = await this.internalHttp.post(this.endpoint.Custom(`SaveAgentLoginsWithDatas?userId=${userId}`, true), requestBody);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    async saveInsurerLogins(requestBody: any) {
        const result = await this.internalHttp.post(this.endpoint.Custom(`SaveInsurerLoginsWithDatas`, true), requestBody);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }
}

export default new UserAgencyLoginService();