import { CrudStoreBase } from './crudStoreBase';
import userAgencyLoginService from '../services/userAgencyLogin/userAgencyLoginService';
import { UserAgencyLoginDto } from '../services/userAgencyLogin/dto/userAgencyLoginDto';
import { UserAgencyLoginSegment } from '../services/userAgencyLogin/dto/userAgencyLoginSegmentEnums';

class UserAgencyLoginStore extends CrudStoreBase<UserAgencyLoginDto>{
	constructor() {
		super(userAgencyLoginService, defaultUserAgencyLogin)
	}

	public async getAgentLoginsByUserId(userId: number) {
		this.dataSet = {
			totalCount: 0, 
			items: []
		};
		
		await userAgencyLoginService.getAgentLoginsByUserId(userId).then((result: any) => {
			this.dataSet = {
				totalCount: result.items.length, 
				items: result.items
			};
		}).catch((error: any) => {
			console.error(error);
		});

		return this.dataSet;
	}

	public async getInsurerLoginsByInsurerId(insurerId: number) {
		this.dataSet = {
			totalCount: 0, 
			items: []
		};
		
		await userAgencyLoginService.getInsurerLoginsByInsurerId(insurerId).then((result: any) => {
			this.dataSet = {
				totalCount: result.items.length, 
				items: result.items
			};
		}).catch((error: any) => {
			console.error(error);
		});

		return this.dataSet;
	}
}

export const defaultUserAgencyLogin: UserAgencyLoginDto = {
	id: '',
    insurerId: 0,
    insurerName: '',
    logoLink: '',
    datas: [],
    segment: UserAgencyLoginSegment.All
}

export default UserAgencyLoginStore;