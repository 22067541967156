export default class Endpoint {
    baseApiUrl = "api/services/app";
    dataSet: string = ""
    restFull: boolean
    constructor(set: string, baseApiUrl = "api/services/app", restFull = false) {
        this.dataSet = set;
        this.baseApiUrl = baseApiUrl;
        this.restFull = restFull;
    }

    Get(id:string = ""): string {
        let restFullId = id ? `/${id}` : "";
        return `${this.baseApiUrl}/${this.dataSet}${this.Rest('Get')}${restFullId}`;
    }
    GetAll(): string {
        return `${this.baseApiUrl}/${this.dataSet}${this.Rest('GetAll')}`;
    }
    Create(): string {
        return `${this.baseApiUrl}/${this.dataSet}${this.Rest('Create')}`;
    }
    Update(): string {
        return `${this.baseApiUrl}/${this.dataSet}${this.Rest('Update')}`;
    }
    Delete(): string {
        return `${this.baseApiUrl}/${this.dataSet}${this.Rest('Delete')}`;
    }

    Rest(ending: string) {
        return this.restFull ? '' : `/${ending}`;
    }

    Custom(path: string, withoutBaseApiUrl?: boolean): string {
        return !!withoutBaseApiUrl ? `${this.dataSet}/${path}` : `${this.baseApiUrl}/${this.dataSet}/${path}`;
    }

    static BlobStorage: Endpoint = new Endpoint("BlobStorage");

    // TOP
    static ProductAttribute: Endpoint = new Endpoint("ProductAttribute", "", false);
    static CustomerGroup: Endpoint = new Endpoint("CustomerGroup", "", false);
    static PolicyCalculation: Endpoint = new Endpoint("PolicyCalculation", "", false);
    static PolicyApplication: Endpoint = new Endpoint("PolicyApplication", "", false);
    static PolicyFinalization: Endpoint = new Endpoint("PolicyFinalization", "", false);
    static PolicyPdf: Endpoint = new Endpoint("PolicyPdf", "", false);
    static PolicyCalculationPdf: Endpoint = new Endpoint("PolicyCalculationPdf", "", false);
    static PolicyDictionary: Endpoint = new Endpoint("PolicyDictionary", "", false);
    static Language: Endpoint = new Endpoint("Language", "", false);
    static Country: Endpoint = new Endpoint("Country", "", false);
    static CountryItems: Endpoint = new Endpoint("CountryItems", "", false);
    // Domain specific
    static VehicleCode: Endpoint = new Endpoint("VehicleCode", "", false);
    static Rating: Endpoint = new Endpoint("Rating", "", false);
    static Gus: Endpoint = new Endpoint("Gus", "", false);
    static AgentClaim: Endpoint = new Endpoint("AgentClaim", "", false);
    static AgentClaimAttachedFiles: Endpoint = new Endpoint("AgentClaimAttachedFiles", "", false);
    static AgentClaimHistory: Endpoint = new Endpoint("AgentClaimHistory", "", false);
    static AgentClaimAmeStatusAttachedFiles: Endpoint = new Endpoint("AgentClaimAmeStatusAttachedFiles", "", false);
    static AgentClaimAmeStatus: Endpoint = new Endpoint("AgentClaimAmeStatus", "", false);
    static Claim: Endpoint = new Endpoint("Claim", "", false);
    static ClaimChildren: Endpoint = new Endpoint("ClaimChildren", "", false);
    static ClaimHome: Endpoint = new Endpoint("ClaimHome", "", false);
    static ClaimLife: Endpoint = new Endpoint("ClaimLife", "", false);
    static ClaimTravel: Endpoint = new Endpoint("ClaimTravel", "", false);
    static ClaimVehicle: Endpoint = new Endpoint("ClaimVehicle", "", false);
    static Contest: Endpoint = new Endpoint("Contest", "", false);
    static Configuration: Endpoint = new Endpoint("Configuration", "", false);
    static ClientContest: Endpoint = new Endpoint("ClientContest", "", false)
    static Product: Endpoint = new Endpoint("Product", "", false);
    static ProductType: Endpoint = new Endpoint("Category", "", false);
    static InsuranceCompany: Endpoint = new Endpoint("Brand", "", false);
    static Insurer: Endpoint = new Endpoint("Insurer", "", false);
    static InsurancePolicy: Endpoint = new Endpoint("Policy", "", false);
    static IndividualOfferAttachedFile: Endpoint = new Endpoint("IndividualOfferAttachedFile", "", false)
    static IndividualOffer: Endpoint = new Endpoint("IndividualOffer", "", false)
    static CancelledPolicy: Endpoint = new Endpoint("CancelledPolicy", "", false);
    static PolicyAttachedFiles: Endpoint = new Endpoint("PolicyAttachedFiles", "", false);
    static PolicyCalculationAttachedFile: Endpoint = new Endpoint("PolicyCalculationAttachedFile", "", false);
    static Prize: Endpoint = new Endpoint("Prize", "", false);
    static ApkAttachedFiles: Endpoint = new Endpoint("ApkAttachedFiles", "", false);
    static PrizeAttachedFiles: Endpoint = new Endpoint("PrizeAttachedFiles", "", false);
    static InsurerAttachedFile: Endpoint = new Endpoint("InsurerAttachedFile", "", false);
    static CancelledPolicyAttachedFiles: Endpoint = new Endpoint("CancelledPolicyAttachedFiles", "", false);
    static ClientAttachedFiles: Endpoint = new Endpoint("ClientAttachedFiles", "", false);
    static Order: Endpoint = new Endpoint("Order", "", false);
    static LoyaltyPointsHistory: Endpoint = new Endpoint("LoyaltyPointsHistory", "", false);
    static SportDiscipline: Endpoint = new Endpoint("SportDiscipline", "", false);
    static SportInsuranceCoverage: Endpoint = new Endpoint("SportInsuranceCoverage", "", false);
    static TravelCountry: Endpoint = new Endpoint("TravelCountry", "", false);
    static TravelCountryCoverage: Endpoint = new Endpoint("TravelCountryCoverage", "", false);
    static InsurerAccessSettings: Endpoint = new Endpoint("InsurerAccessSettings", "", false);
    static TerminationOCContract: Endpoint = new Endpoint("TerminationOCContract", "", false);
    static TerminationOCContractFiles: Endpoint = new Endpoint("TerminationOCContractFiles", "", false);

    // TOP abp
    static AgreementsPdf: Endpoint = new Endpoint("AgreementsPdf", "", false);
    static User: Endpoint = new Endpoint("User");
    static Role: Endpoint = new Endpoint("Role");
    static Sms: Endpoint = new Endpoint("Sms");
    static Pattern: Endpoint = new Endpoint("Pattern");
    static Community: Endpoint = new Endpoint("Community", "", false);
    static Client: Endpoint = new Endpoint("Client", "", false);
    static Payment: Endpoint = new Endpoint("Payment", "", false);
    static Calculation: Endpoint = new Endpoint("PolicyCalculation", "", false);
    static ExternalUsers: Endpoint = new Endpoint("ExternalUsers", "", false);
    static TestSet: Endpoint = new Endpoint("TestSet", "", false);
    static Vehicle: Endpoint = new Endpoint("Vehicle", "", false);
    static VehicleConfig: Endpoint = new Endpoint("VehicleConfig", "", false);
    static InsurerAvailability: Endpoint = new Endpoint("InsurerAvailability", "", false);
    static Agreements: Endpoint = new Endpoint("Agreements", "", false);
    static UserAgencyLogin: Endpoint = new Endpoint("UserAgencyLogin", "", false);
    static GlobalConfiguration: Endpoint = new Endpoint("GlobalConfiguration", "", false);
    static ProductVersion: Endpoint = new Endpoint("ProductVersion", "", false);
}