import * as abpTypings from '../lib/abp';

import { L } from '../lib/abpUtility';
import { routers } from '../components/Router/router.config';
import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import moment from 'moment';
import AppConfig from '../lib/appconfig';
import http from '../services/httpService';

declare var abp: any;

class Utils {
  loadScript(url: string) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.body.appendChild(script);
  }

  extend(...args: any[]) {
    let options,
      name,
      src,
      srcType,
      copy,
      copyIsArray,
      clone,
      target = args[0] || {},
      i = 1,
      length = args.length,
      deep = false;
    if (typeof target === 'boolean') {
      deep = target;
      target = args[i] || {};
      i++;
    }
    if (typeof target !== 'object' && typeof target !== 'function') {
      target = {};
    }
    if (i === length) {
      target = this;
      i--;
    }
    for (; i < length; i++) {
      if ((options = args[i]) !== null) {
        for (name in options) {
          src = target[name];
          copy = options[name];
          if (target === copy) {
            continue;
          }
          srcType = Array.isArray(src) ? 'array' : typeof src;
          if (deep && copy && ((copyIsArray = Array.isArray(copy)) || typeof copy === 'object')) {
            if (copyIsArray) {
              copyIsArray = false;
              clone = src && srcType === 'array' ? src : [];
            } else {
              clone = src && srcType === 'object' ? src : {};
            }
            target[name] = this.extend(deep, clone, copy);
          } else if (copy !== undefined) {
            target[name] = copy;
          }
        }
      }
    }

    return target;
  }

  getPageTitle = (pathname: string) => {
    const route = routers.filter(route => route.path === pathname);
    const localizedAppName = 'TOP';
    if (!route || route.length === 0) {
      return localizedAppName;
    }

    return L(route[0].title) + ' | ' + localizedAppName;
  };

  getRoute = (path: string): any => {
    return routers.filter(route => route.path === path)[0];
  };

  setLocalization() {
    if (!abp.utils.getCookieValue('Abp.Localization.CultureName')) {
      let language = navigator.language.substring(0,2).toLowerCase();
      abp.utils.setCookieValue('Abp.Localization.CultureName', language, new Date(new Date().getTime() + 5 * 365 * 86400000), abp.appPath);
    }
  }

  getCurrentClockProvider(currentProviderName: string): abpTypings.timing.IClockProvider {
    if (currentProviderName === 'unspecifiedClockProvider') {
      return abp.timing.unspecifiedClockProvider;
    }

    if (currentProviderName === 'utcClockProvider') {
      return abp.timing.utcClockProvider;
    }

    return abp.timing.localClockProvider;
  }

  addIfMissing = <TItem extends unknown>(
    item: TItem,
    itemSource: TItem[],
    idPropName: keyof TItem
  ): TItem[] => {
    if (!itemSource || item == null) {
      console.error(
        `Item source (${itemSource}) or adding item (${item}) is incorrect`
      );
    }
    return !itemSource?.some((x) => x[idPropName] === item[idPropName])
      ? [...itemSource, item]
      : itemSource;
  };
}

export default new Utils();

export async function asyncForEach<T>(array: Array<T>, callback: (item: T, index: number, array: Array<T>) => Promise<void>) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

type EnumType = { [s: number]: string };
export function mapEnum(enumerable: EnumType, fn: Function, enumMemberType: string = "number"): any[] {
  let enumMembers: any[] = Object.keys(enumerable).map(key => enumerable[key]);
  let enumValues: number[] = enumMembers.filter(v => typeof v === enumMemberType);
    return enumValues.map((value: number, i: number) => fn(enumMembers[i], value));
}

export function enumToDropdownOptions(enumerable: EnumType, valueAsKey: boolean = false, translated: boolean = false, enumMemberType: string = "number"): IDropdownOption[] {
  return mapEnum(enumerable, (member: string, value: number) => {
      return { key: (valueAsKey ? value : member), text: translated ? L(member) : member }
  }, enumMemberType);
}

export function enumToChoiceGroupOptions(enumerable: EnumType, valueAsKey: boolean = false, translated: boolean = false, enumMemberType: string = "number"): IChoiceGroupOption[] {
  return mapEnum(enumerable, (member: string, value: number) => {
      return { key: (valueAsKey ? value : member), text: translated ? L(member) : member, checked: false }
  }, enumMemberType);
}

export function dateFormat(date: string, format: string = "DD.MM.YYYY, HH:mm", timeToLocal: boolean = false): string {
  if(timeToLocal === true) {
    return moment.utc(date).local().format(format);
  } else {
    return moment(date).format(format);
  }
}

export function dateFormatChat(date: string, format: string = "DD.MM HH:mm", timeToLocal: boolean = false): string {
  if(timeToLocal === true) {
    return moment.utc(date).local().format(format);
  } else {
    return moment.utc(date).format(format);
  }
}

export function mapPathToIcon(pathName: string): string {
  return AppConfig.pagePathToIcon[pathName] ? AppConfig.pagePathToIcon[pathName] : null;
}

export function catchErrorMessage(error: any): string {
  if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.error !== 'undefined' &&
      typeof error.response.data.error.message !== 'undefined' && error.response.data.error.message.length > 0) {
      // Request made and server responded - error.response.data / error.response.status / error.response.headers
      return L(error.response.data.error.message);
  } else if (typeof error.request !== 'undefined' && error.request.length > 0) {
      // The request was made but no response was received
      return L(error.request);
  } else if (typeof error.message !== 'undefined' && error.message.length > 0) {
      // Something happened in setting up the request that triggered an Error
      return L(error.message);
  } else {
    if(typeof error === 'string') {
      return L(error);
    } else {
      console.error(error);
      return L("Error datails in developer console.");
    }
  }
}

export function isJsonString(str: string): boolean {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export function filterBySome(array: any, keyToCompare: string, valueToCompare: any) {
  let arrayItemToReturn;
  
  array.some((arrayItem: any) => {
    if(arrayItem[keyToCompare] === valueToCompare) {
      arrayItemToReturn = arrayItem;
      return true;
    }
    return false;
  });

  return arrayItemToReturn;
}

export function modifyFirstLetter(stringToModify: string, modifyCase: string): string {
  if(typeof stringToModify !== 'string' || stringToModify.length === 0) return stringToModify;
  
  switch(modifyCase) {
    case 'toLowerCase':
      return stringToModify.charAt(0).toLowerCase() + stringToModify.slice(1);
    case 'toUpperCase':
      return stringToModify.charAt(0).toUpperCase() + stringToModify.slice(1);
    case 'capitalizeEachWord':
      let newString: string = "";
      let words: string[] = stringToModify.split(' ');

      words.forEach((word: string) => {
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);

        if(newString.length === 0) {
          newString = capitalizedWord;
        } else {
          newString += word.length > 1 ? ` ${capitalizedWord}` : ` ${word}`;
        }
      });
      return newString;
    default:
      return stringToModify;
  }
}

export function isStringNumeric(str: string) {
  if (typeof str !== "string") return false; // we only process strings! 
  if (str.length === 0) return false;

  return !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export async function getPatternText(patternName: string): Promise<string> {
  let route = '/api/services/app/Pattern/GetPatternForType?inputData='+patternName;
  let result = await http.get(route);
  let textFromPattern = result?.data?.result?.value ?? '';
  
  return textFromPattern;
}

export async function asyncSleep(props: any) {
  return new Promise(r => setTimeout(r, props));
}

export function getNumberWithSpaces(number: number) {
  var parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function peselDecode(pesel: string) {
  if(!pesel || pesel.length === 0) {
    return {valid: false, sex: '', date: new Date()};
  }

  let year: number = parseInt(pesel.substring(0,2), 10);
  let month: number = parseInt(pesel.substring(2,4), 10) - 1;
  let day: number = parseInt(pesel.substring(4,6), 10);
  // Powszechnie uwaza sie, iz daty w numerach pesel obejmuja tylko ludzi urodzonych do 2000 roku. Na szczescie prawodawcy o tym pomysleli i do miesiaca dodawane sa liczby tak, by pesele starczyly az do 23 wieku. 
  
  if(month > 80) {
    year = year + 1800;
    month = month - 80;
  } else if(month > 60) {
    year = year + 2200;
    month = month - 60;
  } else if (month > 40) {
    year = year + 2100;
    month = month - 40;
  } else if (month > 20) {
    year = year + 2000;
    month = month - 20;
  } else {
    year += 1900;
  }

  let birthDate: Date = new Date();
  birthDate.setFullYear(year, month, day);
  
  let weights: number[] = [9,7,3,1,9,7,3,1,9,7];
  let sum: number = 0;
  
  for(let i = 0; i < weights.length; i++) {
    sum += (parseInt(pesel.substring(i,i+1), 10) * weights[i]);
  }
  sum = sum % 10;

  let valid: boolean = (sum === parseInt(pesel.substring(10,11), 10));
  let sex: string = '';
  
  if(parseInt(pesel.substring(9,10), 10) % 2 === 1) { 
    sex = 'male';
  } else {
    sex = 'female';
  }

  return {valid: valid, sex: sex, date: birthDate};
}